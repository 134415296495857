.main-wrapper {
	padding: 0;
	min-width: $min-width;
	width: 100%;
	position: relative;
	min-height: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
	flex: 1 0 auto;
}



.wrapper {
	min-width: $min-width;
	max-width: $max-width;
	padding-right: 4rem;
	padding-left: 4rem;
	margin: 0 auto;
	position: relative;
	width: 100%;
	&-2 {
		max-width: 1520px
	}
}



/* titles */

p {
	padding: 1.2rem 0;
}

h1,
.h1 {

	padding: 0;
font-weight: 700;
font-size: 3.6rem;
line-height: 130%;
color: $color1;
	margin-bottom: 0.8rem;
            
	font-family: $custom-font-family;
}
.h1 {
font-size: 5.4rem;
line-height: 110%;
	margin-bottom: 4.6rem;
}
h2,
.h2 {
font-size: 2.4rem;
line-height: 140%;
line-height: 100%;
	margin: 0 0 0.7rem;
	padding-top: 1.3rem;
	text-align: left;
	color: $color1;
	font-family: $custom-font-family;
            
}
.h2 {
font-weight: bold;
font-size: 5.4rem;
padding-top: 0;
	margin: 0 0 2.4rem;
}
.h-center {
	text-align: center
}


h3,
.h3 {
font-size: 2.4rem;
line-height: 140%;
font-weight: 700;
	color: $color1;
	padding: 3.5rem 0 2.5rem;
	font-family: $custom-font-family;
            
}
.h3 {
font-size: 3.6rem;
line-height: 100%;
	padding:0 0 1.5rem
}
h4,
.h4 {
	font-weight: bold;
	font-size: 2.4rem;
	line-height: 2.8rem;
	color: $text-color;
}
.h-left {
	text-align: left
}

.h-decor-1, .h-decor-3 {
		position: relative;
		padding: 0 10rem;
		&:before {
			content: '';
			width: 7rem;
			height: 6.9rem;
			display: block;
			position: absolute;
			
			left: 2.8%;
			margin-right: 8rem;
			top: 30%;
		}
}
.h-decor-1, .h-decor-2 {
		position: relative;
		padding: 0 10rem;
		&:after {
			content: '';
			margin-left: 8rem;
			width: 8.8rem;
			height: 5.6rem;
			display: block;
			position: absolute;
			    right: 4.2%;
    top: 17%;
		}
}
.h-decor-3:before {
	top: -29%;
	left: 10.8%;
}
.h-decor-2:after {
right: 12.2%;
}
body:not(.loaded) .h-decor-1:before, body:not(.loaded) .h-decor-3:before {
	background: url(../img/leaf-left.svg) no-repeat;
	background-size: contain;
}
body:not(.loaded) .h-decor-1:after, body:not(.loaded) .h-decor-2:after {
	background: url(../img/leaf-right.svg) no-repeat;
	background-size: contain;
}
/* text position */

.text-left {
	text-align: left!important
}

.text-center {
	text-align: center!important
}

.text-right {
	text-align: right!important
}

.nowrap {
	white-space: nowrap!important;
}

.center {
	text-align: center;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.hide {
	display: none !important
}
.colored {
	color:  $color-active;
}
.window-open {
    position: absolute;
    left: 0;
    top: -200%;
    left: -99999px;
    opacity: 0
}
/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/

.content {
	min-width: $min-width;
	text-align: left;
	width: 100%;
	order: 2;
	flex-grow: 1;
	position: relative;
	z-index: 1;
}

.main-wrap {
	display: flex;
	flex-direction: column-reverse;
}

.content-wrap {
	background: #FFFFFF;
	padding: 3.3rem 2.5rem 2.1rem;
	max-width: 119.6rem;
	margin: 0 auto
}

.more-text {
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 1.9rem;
	cursor: pointer;
	color: $color2;
	margin-top: 1.3rem;
	span {
		transition: color .3s ease-in
	}
	i {
		margin-left: 1rem;
		font-size: 1rem;
		color: rgba($color2, .5);
		display: inline-block;
		transition: transform .4s ease
	}
	&.active {
		i {
			transform: rotateX(180deg)
		}
	}
}

.web .more-text:hover span {
	border-bottom-color: transparent
}

.btn {
    
    position: relative;
    overflow: hidden;
    transition: all 0.3s linear;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    height: 6rem;
    padding: 0 4rem;
    font-family: $base-font-family;
    text-align: center;
    cursor: pointer;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 0px 20px 20px rgba(217, 61, 89, 0.2);
    background: linear-gradient(90deg, rgba(249, 73, 115, 0.991703) 0%, rgba(255, 154, 160, 0.991703) 50%, rgba(249, 73, 115, 0.991703) 100%);
    text-transform: uppercase;
    background-size: 200% 100%;
    background-position: 0% 0;
    color: #fff;
    border: 0;
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: 900;

    border-radius: 3rem;
box-shadow: 0px 1.5rem 2.5rem rgba(230, 51, 94, 0.25);

}

.web {
	.btn:hover {
        color: #fff;
        background-position: 100% 0;

	}
}

.btn-2 {
    font-family: $base-font-family;
	background: #1B2B7C;
box-shadow: 0px 1.5rem 2.5rem rgba(27, 43, 124, 0.15);
border-radius: 30px;
	color: #fff;
	border: 0;
	height: 4.4rem;
    padding: 0 3rem;
	line-height: 4.2rem;
	font-weight: bold;
font-size: 1.6rem;

transition: background .4s ease;
}
.web {
	.btn-2:hover {
        color: #fff;
        background: $color-active

	}
}
.btn-3 {
	color: $color1;
	background: rgba(27, 43, 124, 0.1);
transition: background .4s ease, color .4s ease;
}
.web {
	.btn-3:hover {
		color: #fff;
        background:#1B2B7C

	}
}
.form-control, input[type="text"], input[type="email"], input[type="tel"], textarea {
border: 1px solid #DBDFF3;
background: transparent;
border-radius: 5rem;
	color: $text-color;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	font-size: 1.4rem;
	font-family: $base-font-family;
	width: 100%;
	height: 6rem;
	font-weight: 400;
	transition: border .3s ease-in;
	&:focus {
		border-color: #000;
	}
	&.error {
		border-color: red
	}
}

::-webkit-input-placeholder {
	color: $placeholder
}

::-moz-placeholder {
	color: $placeholder
}

:-moz-placeholder {
	color: $placeholder
}

:-ms-input-placeholder {
	color: $placeholder
}

textarea {
	height: 11.8rem;
	resize: none;
	line-height: 160%;
	padding-top: 1.8rem;
	padding-bottom: 1.2rem;
	border-radius: 2rem;
	vertical-align: top;
}


.box-fileds {
	margin: 0 -1rem;
	display: flex;
	flex-wrap: wrap;
	.box-filed {
		width: calc(50% - 2rem);
		margin: 0 1rem 2rem;
	}
	.box-filed_btn {
		width: 14.4rem;
	}
	.box-field__accept {
		padding-top: 1.4rem;
		width: calc(100%  - 20.4rem);
	}
}


.box-field {
	margin-bottom: 3rem;

	&_button {
		margin-top: 1rem;
		display: flex;
	}

}

.btn_submit {
	width: 100%;
	max-width: 39.2rem
}

.box-field__label {
	color:  $color1;
	margin-bottom: .6rem;
	sup {
		vertical-align: top;
		color: $color-active
	}
}


.label-placeholder {
	color: rgba(37, 35, 35, .6);
	text-align: left;
	padding-left: 0;
	top: .6rem;
	display: block;
	position: absolute;
	left: 0;
	font-size: 1.4rem;
	font-weight: 500;
}

label.error {
	color: red;
	font-size: 1.3rem;
	font-weight: 500;
	margin: .8rem 0 0 1.5rem
}

.box-field__input {
	position: relative;
}

.icon-telegram {
	color: #039BE5;
}

.icon-viber {
	color: #7F40BD;
}

.icon-whatsapp {
	color: #4CAF50;
}




.label-placeholder {
	letter-spacing: 0.02em;
	position: absolute;
	left: 0;
	text-align: left;
	padding-left: 0;
	top: .7rem;
	font-weight: 400;
	display: block;
	position: absolute;
	font-size: 1.6rem;
	line-height: 2.1rem;
	color: rgba(#FFF, .8);
	pointer-events: none
}

.filled {
	.label-placeholder {
		font-size: 80%;
		top: -1.8rem;
		opacity: 0
	}
}



.checkbox-element {
	cursor: pointer;
	position: relative;
	display: inline-flex;
	input {
		flex-shrink: 0;
		display: inline-block;
		margin-top: 0;
		position: absolute;
		left: 0;
		top: 0;
		&:checked {
			&~.check {
				border-color: transparent;
				&:after {
					opacity: 1;
				}
			}
		}
		;
	}
}

.socials {
	display: flex;
	align-items: center;
	line-height: 1.4;
	&__item {
		margin-right: 1rem;
		&:last-child {
			margin-right: 0;
		}
	}
	&__link {
			color: $color1;
	line-height: 140%;
	font-weight: 900;
		font-size: 1.4rem
	}
}
.socials__link:after {
    content: '';
    display: inline-block;
 width: .6rem;
height: .6rem;
    left: 0px;
    top: 6.5px;
    border-radius: 50%;
    border: 2px solid #FB5472;
    vertical-align: middle;
    margin: 0 0 .4rem 10px;
}
.socials__item:last-child .socials__link:after {
	display: none;
}
.socials-2 {
	margin-top: 4.8rem;
	display: flex;
	align-items: center;
	&__item {
	display: flex;
	align-items: center;
	margin-right: 3rem;
	&:hover .socials-2__link{
		background: linear-gradient(270.11deg, #5CD5FF 0.07%, #83F0FF 99.88%);
box-shadow: 0px 3.1rem 4.9rem #CCF2FD;
	}
	}
	&__title {
		font-weight: bold;
font-size: 1.6rem;
line-height: 150%;
color: $color1;
margin-right: 1.4rem;
	}
	&__link {
		background: linear-gradient(270.11deg, #90D5ED 0.07%, #9FE6FF 99.88%);
box-shadow: 0px 3.1rem 4.9rem #CCF2FD;
border-radius: 1.6rem;
width: 8rem;
height: 8rem;
	display: flex;
	align-items: center;
	justify-content: center;
img {
	filter: brightness(0) invert(1);
}

}
}
.checkbox-element {
	display: inline-flex;
	input {
		opacity: 0
	}
	input[type="checkbox"]:hover,
	input[type="radio"]:hover {
		border-color: #000;
	}
	input[type="checkbox"]:checked ~ .checkbox-text:after,
	input[type="radio"]:checked ~ .checkbox-text:after{
		opacity: 1
	}
}

.checkbox-element input {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	display: inline-block;
	cursor: pointer;
	margin-top: -.2rem;
}

.checkbox-text {
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 150%;
	cursor: pointer;
		position: relative;
		padding-left: 24px;	
		display: block;
	}
	.checkbox-text:before{
		content: '';
		background: transparent no-repeat center;
		display: block;
		border: 1px solid #DBDFF3;

border-radius: .3rem;
		width: 14px;
		height: 14px;
		padding: 0;
		top: 0;
		left: 0;
		position: absolute;
		flex-shrink: 0;
		background: none;
		border-radius: .3rem;
		margin: .5rem 0 0;
		vertical-align: top
	}
	.checkbox-text:after{
		content: '';
		background-color: transparent;
		background-image: url(../img/icons-svg/check.svg);
		background-repeat: no-repeat;
		width: 14px;
		height: 14px;
		position: absolute;
		opacity: 0;
		top: 0;
		margin: .5rem 0 0;
		left: 0;
		background-position: 45% 40%;
		background-size: 14px 10px;
		transition: background 0.3s ease-in, border 0.3s ease-in;
	
	}

.hide {
	display: none;
}

.color-decor {
	color: $color1;
}
.color-decor-2 {
	color: $color-active-2;
}

.box-field.sucsses-form-message {
	margin: -2.4rem 0 0px;
}
.sucsses-form-message {
	font-size: 1.4rem;

line-height: 2.2rem;
	display: flex;
	align-items: center;
}
.sucsses-icon {
	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24C5.3724 24 0 18.6276 0 12ZM16.4484 10.4484C16.667 10.2221 16.7879 9.91896 16.7852 9.60432C16.7825 9.28968 16.6563 8.98871 16.4338 8.76622C16.2113 8.54373 15.9103 8.41753 15.5957 8.41479C15.281 8.41206 14.9779 8.53301 14.7516 8.7516L10.8 12.7032L9.2484 11.1516C9.02208 10.933 8.71896 10.8121 8.40432 10.8148C8.08968 10.8175 7.78871 10.9437 7.56622 11.1662C7.34373 11.3887 7.21753 11.6897 7.21479 12.0043C7.21206 12.319 7.33301 12.6221 7.5516 12.8484L9.9516 15.2484C10.1766 15.4734 10.4818 15.5997 10.8 15.5997C11.1182 15.5997 11.4234 15.4734 11.6484 15.2484L16.4484 10.4484Z' fill='%23A2E44E'/%3E%3C/svg%3E%0A");
	width: 2.4rem;
	height: 2.4rem;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	flex-shrink: 0;
	margin-right: .5rem;
	&:before {
		display: none;
	}
}



.scrollDisabled {
	overflow: hidden;
	position: fixed !important;
	margin-top: 0;
	width: 100%;
	z-index: 0;
}

.scrollDisabled.web,
.scrollDisabled.web.compensate-for-scrollbar,
.scrollDisabled.web .header {
	padding-right: 1.7rem
}

.scrollDisabled .main-wrapper {
	overflow: visible;
}

@media screen and (min-width: 768px) {
	.mob-show {
		display: none !important;
	}
}

@media screen and (min-width: 1024px) {
	.tablet-show {
		display: none;
	}
}



@media screen and (max-width: 1200px) {

	h1, .h1, .h2 {
    font-size:4.6rem;
}
.h-decor-2:after {
    right: 2.2%;
}
.h-decor-3:before {
	left: 2%
}
}



@media screen and (max-width: 1023px) {

	.wrapper {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}
	.messages-icon__ic {
		width: 4.5rem;
		height: 4.5rem;
	}

	h1, .h1, .h2 {
    font-size:4.6rem;
}
.h-decor-1 , .h-decor-2, .h-decor-3  {
    	padding: 0 5rem;
}
	.tablet-hide {
		display: none !important
	}
	.btn {
		height: 5.6rem;
		line-height: 5.6rem;
		font-size: 1.4rem;
		    padding: 0 5rem;
	}
	.btn {
		padding: 0 3rem
	}
	.h-decor-1:before, .h-decor-3:before {
		left: -2.2rem;
		    top: 18%;

	}
	.h-decor-1:after, .h-decor-2:after {
		right: 1%;
	}
}

@media screen and (max-width: 767px) {
	h1{
		font-size: 2.2rem;
		margin-bottom: .9rem
	}
	.h1, .h2 {
		font-size: 2.4rem;
		    margin-bottom: 2.1rem;
	}
	

	.mob-show {
		display: block;
	}
	.mob-hide {
		display: none !important;
	}
	.wrapper {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}
	.box-field {
		margin-bottom: 1.6rem;
	}
	.box-field.sucsses-form-message {
		margin: -.3rem 0 -1.9rem
	}
	textarea.form-control {

    line-height: 1.5;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
	.messages-icon {
		right: .5rem;
	}
	.box-field__label {
    margin-bottom: 1.1rem;
}

	.box-field_button {
    margin-top: 3.2rem;
}
.socials-2__item {
	width: 50%;
	margin: 0 auto;
	padding: 0 1.5rem;
	display: block;
	text-align: center;

}
.socials-2__link {
	margin: 0 auto;
}
.socials-2__title {
	font-size: 1.2rem;
	margin: 0 0 1.1rem;
}
.socials-2 {
	    margin: 3.8rem auto 0;
	    max-width: 34rem;
	justify-content: center;
}
.socials-2__link {

    border-radius: 1.6rem;
    width: 5.6rem;
    height: 5.6rem;
}
	.content-wrap {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		padding: 2.9rem 2.7rem 3.5rem
	}
	.tab {
		padding-left: 0
	}

.h-decor-1:before, .h-decor-3:before, .h-decor-1:after, .h-decor-2:after {
    display: none;
}
.h-decor-1, .h-decor-2, .h-decor-3 {
    padding: 0;
}
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
	.text-gradient,
	.arrow-down i.text-gradient {
		background: none;
		color: #41FF6B
	}
}

@media screen and (max-width: 359px) {
	.sm-mob-hide {
		display: none !important
	}
}